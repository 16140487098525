// IframeComponent.js
import React from 'react';

const IframeComponent = () => {
  return (
    <iframe 
      src="https://web.powerva.microsoft.com/environments/Default-18dcbebf-58f7-4e3f-b90c-845c7fb8ff4b/bots/crbce_systemAccessRequests/webchat?__version__=2" 
      style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', border: 'none' }}
      title="Embedded Chat"
    ></iframe>
  );
}

export default IframeComponent;
